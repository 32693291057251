/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

/* .flex-container > .flex-row-item {
    flex: 1 0 21%;
    height: 100px;
  } */
.flex-row-item {
  flex: 1 0 21%;
  height: 100px;
  flex-grow: 1;
}

.origin-text {
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 10px;
}

.button-style {
  float: right;
  margin: 20px 0;
}

.card {
  z-index: 0;
  background-color: #ECEFF1;
  padding-bottom: 20px;
  margin-top: 90px;
  margin-bottom: 90px;
  border-radius: 10px
}

.top {
  padding-top: 40px;
  padding-left: 13% !important;
  padding-right: 13% !important
}

.wrapper {
  width: 330px;
  font-family: 'Helvetica';
  font-size: 14px;
  border: 1px solid #CCC;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.StepProgress li {
  position: relative;
}

.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #CCC;
}

.StepProgress-item {
  position: relative;
  counter-increment: list;
}

.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}

.StepProgress-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -35px;
  width: 12px;
  height: 12px;
  border: 2px solid #CCC;
  border-radius: 50%;
  background-color: #FFF;
}

.StepProgress-item.is-done::before {
  border-left: 2px solid green;
}

.StepProgress-item.is-done::after {
  font-size: 10px;
  color: #FFF;
  text-align: center;
  border: 2px solid green;
  background-color: green;
}

.StepProgress-item.current::before {
  border-left: 2px solid green;
}

.StepProgress-item.current::after {
  /* content: counter(list); */
  padding-top: 1px;
  width: 19px;
  height: 18px;
  top: -4px;
  left: -42px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid green;
  background-color: white;
}

.StepProgress strong {
  display: block;
}

.lable-class {
  position: absolute;
  left: 0;
}

.events li {
  display: flex;
  color: #999;
  width: 100%;
}

.events time {
  position: relative;
  padding: 0 1.5em;
  color: black;
  width: 27%;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: #fff;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em;
}

.events .is-done::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: -13.5px;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  background: green;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em;
}

/* Alert */
.loading {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
  width: 76.5%;
  /* color: black; */
}

.events span strong {
  color: black;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 1px #ccc solid;
}

.events .is-line-done::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 13px;
  height: 100%;
  border-left: 1px green solid;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
  width: 100%;
}

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}



.is-sticky {

  z-index: 000;
}

.marginRight {
  margin-right: 10px;
}

.unique_field .Polaris-Labelled__LabelWrapper .Polaris-Label
{
  visibility: hidden;
}
.dimesion_one>div {
  margin-right: 10px;
}

.dimesion_two >div {
  margin-right: 10px;
}
.dimension_two
{
  display: flex;
  width: 91%;
}